export const FirebaseConfig = {
	"projectId": "exicube-50abb",
	"appId": "1:647813950916:web:739233e1188e51c3a1c891",
	"databaseURL": "https://exicube-50abb-default-rtdb.firebaseio.com",
	"storageBucket": "exicube-50abb.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyDL0QKCmBSdcKD8tVkEQcWAVDxdTwRvQXc",
	"authDomain": "exicube-50abb.firebaseapp.com",
	"messagingSenderId": "647813950916",
	"measurementId": "G-0P0WX5ZDYT"
};